<template>
    <a href="#main" tabindex="1" class="skip">Skip to main content</a>
    <mainDiv id="main">
      <div class="title1" role="heading">Amenities</div>      
      <div class="title2" role="heading">{{getAmen('comfortsTitle')}}</div>
      <ul>
          <div  class="amenList" v-for="str in getAmen('comforts').split('\n')" :key="str">
              <li>{{str}}</li>
          </div>
      </ul>
      <div class="title2" role="heading">{{getAmen('pleasuresTitle')}}</div>
      <ul>
          <div  class="amenList" v-for="str in getAmen('pleasures').split('\n')" :key="str">
              <li>{{str}}</li>
          </div>
      </ul>
      <div class="title2" role="heading">{{getAmen('helpsTitle')}}</div>
      <ul>
          <div  class="amenList" v-for="str in getAmen('helps').split('\n')" :key="str">
              <li>{{str}}</li>
          </div>
      </ul><div class="title2" role="heading">{{getAmen('outdoorFunTitle')}}</div>
      <ul>
          <div  class="amenList" v-for="str in getAmen('outdoorFun').split('\n')" :key="str">
              <li>{{str}}</li>
          </div>
      </ul><div class="title2" role="heading">{{getAmen('extrasTitle')}}</div>
      <ul>
          <div  class="amenList" v-for="str in getAmen('extras').split('\n')" :key="str">
              <li>{{str}}</li>
          </div>
      </ul>
      <div class="space1"></div>
      <div class="title1" role="heading">{{getAmen('spaTitle')}}</div>      
      <div class="space2"></div>
      <div class="text2" v-html="getAmen('spa1')" />
      <div class="space3"></div>
      <div class="text2">{{getAmen('spa2')}}</div>
    </mainDiv>
</template>

<script>
import MainDiv from '../MainDiv.vue';
export default {    
  components: {
    'mainDiv': MainDiv
  },
  data() {
    return {
      comforts:'Comfortable bed with quality cotton sheets in lovely rooms\nLuxurious robes\nPlush towels\nCeiling fans in most rooms\nHair dryers, iron/ironing board upon request\nComplimentary home-made snacks and beverages\nFull hot breakfast\nPersonal attention to meet your every need',
    }
  },
  methods: {
    getAmen(name){
      return this.$store.getters.getAmen(name);
    }
  },
  mounted() {
    window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'amenities' }}));
  }
}
</script>
<style scoped>
.amenList > li {
    list-style-type: circle;
}
.skip {
  position: absolute;
  left: -10000px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.skip:focus {
  left:0;
  width: auto;
  height: auto;
}
.space1 {
  height:30px;
}
.space2 {
  height:10px;
}
.space3 {
  height:20px;
}
</style>